import axios from 'axios'

const test = 'http://192.168.0.114:4000/'
const prod = 'https://docs.lutocuritiba.com.br/'

export default axios.create({
    baseURL: prod,
    headers: {
        'Content-Type': 'multipart/form-data'
    },
})
